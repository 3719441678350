<template>
  <div>
    <vs-popup :title="`Lista Acessórios - ${popUpAcessorios.veiculo.placa}`" :active.sync="popUpAcessorios.exibir" v-if="popUpAcessorios.exibir">
       <vs-table :data="listaAcessorios" class="mt-2" noDataText="Nenhum acessório" stripe hoverFlat >
          <template slot="thead">              
              <vs-th >Marca</vs-th>
              <vs-th >Tipo</vs-th>            
              <vs-th >Modelo</vs-th>            
              <vs-th >Serial</vs-th>          
              <vs-th >Valor Mensal</vs-th>                            
              <vs-th >Local Instalação</vs-th> 
          </template>
          <template slot-scope="{data}">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                <vs-td class="p-0"><small style='font-family: Verdana, sans-serif;'>{{tr.marca}}</small></vs-td>                
                <vs-td class="p-0"><small style='font-family: Verdana, sans-serif;'>{{tr.tipo}}</small></vs-td>  
                <vs-td class="p-0"><small style='font-family: Verdana, sans-serif;'>{{tr.modelo}}</small></vs-td>  
                <vs-td class="p-0 text-center"><small style='font-family: Verdana, sans-serif;'>{{tr.serial}}</small></vs-td>  
                <vs-td class="p-0 text-center"><small style='font-family: Verdana, sans-serif;'>{{tr.valorIncrementoNaMensalidadeFormatado}}</small></vs-td>                 
                <vs-td class="p-0 text-center"><small style='font-family: Verdana, sans-serif;'>{{tr.localInstalacaoNoVeiculo}}</small></vs-td>                 
              </vs-tr>
          </template>
       </vs-table>     
    </vs-popup> 
    <vs-row class="p-0">
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12"> 
            <vx-card class="mr-4 p-0">
                 <label class="vs-input--label">Cliente:</label>
                 <v-select  
                    label="nome"                                                                 
                    :options="listaClientes"  
                    :filter="pesquisarCliente"
                    v-model="dadosAtivacaoAcessorio.clienteSelecionado"      
                    @input="fetchListaVeiculos"                                  
                    class="w-full vs-input--label">
                    <template v-slot:option="option">                        
                        <span style='font-family: Verdana, sans-serif;'> {{ option.CNPJCPF }} </span> - {{ option.nome }}  - {{ option.razaoSocial }}
                    </template>
                </v-select>  
                
            </vx-card>
        </vs-col>
    </vs-row> 
    <vs-row v-if="exibirMensagemNenhumDadoEncontrado">
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">           
            <vs-alert class="mt-4 mr-4" color="warning" title="Atenção">
                Não existem veículos ativos.
            </vs-alert>                          
        </vs-col>
    </vs-row>   
    
    <div >
        <form-wizard ref="fwSubsituicao" color="rgba(var(--vs-primary), 1)" errorColor="rgba(var(--vs-warning), 1)" :title="null" :subtitle="null" stepSize="sm" backButtonText="<" nextButtonText=">" finishButtonText="Ativar" @on-complete="ativar" @on-error="exibirMenssageErro">
            <tab-content :title="getTituloTab1" class="mb-5" icon="feather icon-truck" :before-change="validarPasso1">
                <!-- tab 1 content -->
                <div class="vx-row">
                    <div class="vx-col w-full">
                            <vs-table :data="listaVeiculos" class="tabelaComScroll w-full mr-4" noDataText="..." maxItems="500" search hoverFlat stripe v-model="veiculoSelecionado" @selected="selecionarVeiculo">
                            <template slot="header">
                                <h6 class="ml-2 mt-2" v-if="listaVeiculos.length > 0 "> {{listaVeiculos.length}} veículos</h6>                    
                            </template>
                            <template slot="thead">
                                <vs-th class="p-0">Veículo</vs-th>                    
                                <vs-th class="p-0">Placa</vs-th>                    
                                <vs-th class="p-0">Rastreador Principal</vs-th>
                                <vs-th class="p-0">Operadora GSM</vs-th>
                                <vs-th class="p-0">Ativação</vs-th>            
                                <vs-th class="p-0">Acessórios Ativos</vs-th>        
                            </template>
                            <template slot-scope="{data}">
                                <vs-tr :key="indextr" v-for="(tr, indextr) in data" :data="tr" style="border-bottom-style: solid; border-bottom-width: 1px; border-bottom-color: rgba(255, 255, 255, 0.2);">
                                    <vs-td  class="p-0"><small>{{tr.marca}} {{tr.modelo}} </small> </vs-td>
                                    <vs-td  class="p-0"><small class="font-bold" style='font-family: Verdana, sans-serif;'> {{tr.placa}} </small></vs-td>
                                    <vs-td  class="p-0">
                                            <small class="font-bold">{{tr.codigoModulo}}</small>  <small>{{tr.marcaModulo}}</small>
                                    </vs-td>
                                    <vs-td class="p-0">
                                        <small class="font-bold">{{tr.operadoraGSM}}</small> <small>{{tr.numeroGSM}}</small>
                                    </vs-td>       
                                    <vs-td class="p-0"><small style='font-family: Verdana, sans-serif;'>{{tr.dataAtivacao}}</small></vs-td>                                                           
                                    <vs-td  class="p-0">
                                        <div class="flex">
                                            <vx-tooltip text="Listar acessórios">   
                                                <vs-button size="small" class="mb-1" color="primary" type="filled" icon-pack="feather" icon="icon-search" @click="abrirPopUpListaAcessorios(tr)"></vs-button>
                                            </vx-tooltip>                                
                                        </div>                            
                                    </vs-td>
                                </vs-tr>
                            </template>
                        </vs-table>
                    </div>
                </div>                
            </tab-content>

            <!-- tab 2 content -->
            <tab-content title="2. Dados do Acessório" class="mb-5" icon="feather icon-tablet" :before-change="validarPasso2">
                    <div class="vx-row">  
                        <div class="vx-col md:w-1/3">
                            <vx-card>                                
                                <label class="vs-input--label ml-1  mt-2">Local</label>
                                <v-select                                               
                                    :options="CONSTANTE_LOCAL_INSTALACAO_ACESSORIO_MODULO" 
                                    v-model="dadosAtivacaoAcessorio.localInstalacao" 
                                    :reduce="r => r.data"          
                                    @input="alterarLocalInstalacao"           
                                    class="w-full vs-input--label"
                                    >
                                </v-select> 

                                <label class="vs-input--label ml-1  mt-2" v-if="exibirSensor">Sensor</label>
                                <v-select                                               
                                    :options="CONSTANTE_TIPOS_AVISO_AUX" 
                                    v-model="dadosAtivacaoAcessorio.sensor" 
                                    :reduce="r => r.data"                     
                                    class="w-full vs-input--label"
                                    v-if="exibirSensor">
                                </v-select> 

                                <label class="vs-input--label ml-1 mt-2" v-if="exibirSensorRS232">Sensor RS232</label>
                                <v-select                                               
                                    :options="CONSTANTE_TIPOS_SENSOR_RS232" 
                                    v-model="dadosAtivacaoAcessorio.sensorRS232" 
                                    :reduce="r => r.data"                     
                                    class="w-full vs-input--label"
                                    v-if="exibirSensorRS232">
                                </v-select>

                                
                            </vx-card>
                        </div>                      
                        <div class="vx-col md:w-1/3">
                            <vx-card>
                                <h5> Acessório</h5>
                                <label class="vs-input--label ml-1">Marca</label>
                                <v-select                                               
                                    :options="CONSTANTE_MARCAS_ACESSORIO_MODULO" 
                                    v-model="dadosAtivacaoAcessorio.acessorio.marca" 
                                    :reduce="r => r.data"                     
                                    class="w-full vs-input--label">
                                </v-select> 
                                <vs-input label="Serial"  v-model="dadosAtivacaoAcessorio.acessorio.serial" class="w-full mt-4 ml-1" />
                                <vs-button class="ml-1 mt-2" size="small" @click="verificarDisponibilidade" :disabled="!dadosAtivacaoAcessorio.acessorio.marca || !dadosAtivacaoAcessorio.acessorio.serial || !dadosAtivacaoAcessorio.localInstalacao" color="success">Verificar Disponibilidade</vs-button>
                            </vx-card>                            
                        </div>
                        <div class="vx-col md:w-1/3">
                            <vx-card>
                                <h5 v-if="dadosAcessorioSelecionado"> {{ dadosAcessorioSelecionado }}</h5>
                                
                                <label class="vs-input--label">Valor Mensalidade R$ </label>
                                <vue-autonumeric class="vs-inputx vs-input--input normal hasValue w-full" :disabled="true" v-model="dadosAtivacaoAcessorio.valorIncrementoNaMensalidade" :options="[{ digitGroupSeparator: '.', decimalCharacter: ',', minimumValue: '0', decimalPlaces:'2' }]"></vue-autonumeric>

                                <label class="vs-input--label ml-1 mt-2" v-if="exibirCamposSensorGenerico">Nome Sensor</label>
                                <vs-input v-model="dadosAtivacaoAcessorio.nomeSensorGenerico" class="w-full ml-1" v-if="exibirCamposSensorGenerico"/>
                            </vx-card>
                        </div>
                    </div>
                    
            </tab-content>
        </form-wizard>
    </div>
        
  </div>
</template>

<script>
import axios from "@/axios.js"
import VueAutonumeric from 'vue-autonumeric/src/components/VueAutonumeric.vue';
import {FormWizard, TabContent} from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { CONSTANTE_MARCAS_ACESSORIO_MODULO } from '@/constantesComboBox.js'
import { CONSTANTE_LOCAL_INSTALACAO_ACESSORIO_MODULO } from '@/constantesComboBox.js'
import { CONSTANTE_TIPOS_AVISO_AUX } from '@/constantesComboBox.js'
import {CONSTANTE_TIPOS_SENSOR_RS232} from '@/constantesComboBox.js'

export default {   
    components: {
        FormWizard,
        TabContent,
        VueAutonumeric
    }, 
    created() {
        this.CONSTANTE_MARCAS_ACESSORIO_MODULO = CONSTANTE_MARCAS_ACESSORIO_MODULO;
        this.CONSTANTE_LOCAL_INSTALACAO_ACESSORIO_MODULO = CONSTANTE_LOCAL_INSTALACAO_ACESSORIO_MODULO;
        this.CONSTANTE_TIPOS_AVISO_AUX = CONSTANTE_TIPOS_AVISO_AUX;
        this.CONSTANTE_TIPOS_SENSOR_RS232 = CONSTANTE_TIPOS_SENSOR_RS232;
    },
    computed: {
        getTituloTab1() {
            if (this.veiculoSelecionado) {
                return "1. Veículo selecionado: " + this.veiculoSelecionado.placa;
            } else {
                return "1.Selecione o veículo.";
            }
        },
        dadosAcessorioSelecionado() {
            if (this.dadosAtivacaoAcessorio.acessorio.tipo) {
                return "Acessório do tipo " + this.dadosAtivacaoAcessorio.acessorio.tipo + " modelo " + this.dadosAtivacaoAcessorio.acessorio.modelo + " encontrado. ";
            }
            return null;
        },
        exibirSensor(){
            if (this.dadosAtivacaoAcessorio.localInstalacao === "ENTRADA1" || this.dadosAtivacaoAcessorio.localInstalacao === "ENTRADA2" 
            || this.dadosAtivacaoAcessorio.localInstalacao === "ENTRADA3" ) {
                return true;
            }
            return false;
        },    
        exibirSensorRS232(){
            if (this.dadosAtivacaoAcessorio.localInstalacao === "SENSOR_RS232") {
                return true;
            }
            return false;
        },
        exibirCamposSensorGenerico(){
            if (!this.dadosAtivacaoAcessorio || !this.dadosAtivacaoAcessorio.sensor) {
                return false;
            }

            if (this.dadosAtivacaoAcessorio.sensor === "AvisoAux0" || this.dadosAtivacaoAcessorio.sensor === "AvisoAux1" 
            || this.dadosAtivacaoAcessorio.sensor === "AvisoAux2" ) {
                return true;
            }
            return false;
        }       
    },
    data() {
        return {            
            dadosAtivacaoAcessorio: {
                acessorio : { marca: null, serial: null },
                acessorioDisponivel: false,
                veiculo: null,
                clienteSelecionado: null,
                valorIncrementoNaMensalidade: null,
                dadosAdicionaisClienteSelecionado: null,
                localInstalacao: null,
                sensor: null,
                sensorRS232: null,
                nomeSensorGenerico: null,
            },
            listaClientes: [],
            listaVeiculos: [],    
            listaAcessorios: [],          
            exibirMensagemNenhumDadoEncontrado: false,   
            veiculoSelecionado: null,       
            popUpAcessorios: {
                exibir: false,
                veiculo: null,
                acessorioSelecionado: null
            },
            publicPath: process.env.BASE_URL
        }
    },
    methods: {
        alterarLocalInstalacao(item){
            this.dadosAtivacaoAcessorio.localInstalacao = item;
            this.dadosAtivacaoAcessorio.sensor = null;
            this.dadosAtivacaoAcessorio.sensorRS232 = null;
            this.dadosAtivacaoAcessorio.nomeSensorGenerico = null;            
        },       
        abrirPopUpListaAcessorios(tr) {
            this.popUpAcessorios.exibir = true;
            this.popUpAcessorios.veiculo = tr;
            this.fetchListaAcessorios();
        },
        fetchListaAcessorios() {     
            this.$vs.loading() 
            const params = new URLSearchParams();
            params.append('method', 'FindAll');
            params.append('outputFormat', 'JSON');
            params.append('idVeiculo', this.popUpAcessorios.veiculo.idVeiculo);

            axios.post("/AcessorioModuloRest", params, {              
                    credentials: 'include',
                    responseEncoding: 'iso88591',
                    withCredentials: true } )
            .then((response) => {
                this.$vs.loading.close();
                if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                    this.$router.push({ path: '/' });
                } else {
                    this.listaAcessorios = response.data;                          
                }
            })
            .catch((error) => { 
                this.$vs.loading.close();
                /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - fetchListaPoI - " + error); 
            })    
                                
        },
        selecionarVeiculo(tr) {
            this.veiculoSelecionado = tr;
            this.dadosAtivacaoAcessorio.veiculo = tr;            
        },
        exibirMenssageErro: function(errorMsg){
            if (errorMsg) {
                this.$vs.notify({
                    title: "Erro", text: errorMsg, time: 10000,
                    iconPack: 'feather', icon: 'icon-alert-circle', color: 'danger' });
            }
        },
        valorValido(valor){
           if (!valor && valor !== 0) {
               return false;
           }
            
            return true
        },
        validarPasso1() {
            let self_ = this;

            return new Promise((resolve, reject) => {
                if (self_.dadosAtivacaoAcessorio.veiculo) {
                    self_.dadosAtivacaoAcessorio.acessorioDisponivel = false;
                    return resolve(true);
                }
                return reject("Selecione um veículo");
            });
        },
        validarPasso2() {
            let self_ = this;

            return new Promise((resolve, reject) => {
                if (!self_.dadosAtivacaoAcessorio.acessorio.marca) {
                    return reject("Selecione a marca do módulo novo");
                }
                if (!self_.dadosAtivacaoAcessorio.acessorio.serial) {
                    return reject("Informe o serial do módulo novo");
                }
                if (!self_.dadosAtivacaoAcessorio.acessorioDisponivel) {
                    return reject("Clique no botão para verificar a disponibilidade do módulo novo");
                }

                if (!this.valorValido(self_.dadosAtivacaoAcessorio.valorIncrementoNaMensalidade)) {
                    return reject("Informe o valor da mensalide");
                }

                if (!self_.dadosAtivacaoAcessorio.localInstalacao) {
                    return reject("Informe o local");
                }

                if (self_.dadosAtivacaoAcessorio.localInstalacao === "ENTRADA1" || self_.dadosAtivacaoAcessorio.localInstalacao === "ENTRADA2" 
                || self_.dadosAtivacaoAcessorio.localInstalacao === "ENTRADA3") {
                    if (!self_.dadosAtivacaoAcessorio.sensor) {
                        return reject("Informe o tipo do sensor da entrada");
                    } else if (self_.dadosAtivacaoAcessorio.sensor === 'AvisoAux0' || self_.dadosAtivacaoAcessorio.sensor === 'AvisoAux1' || self_.dadosAtivacaoAcessorio.sensor === 'AvisoAux2') {
                        if (!self_.dadosAtivacaoAcessorio.nomeSensorGenerico) {
                            return reject("Informe o nome do sensor genérico");
                        }   
                    }
                } else if (self_.dadosAtivacaoAcessorio.localInstalacao === "SENSOR_RS232") {
                    if (!self_.dadosAtivacaoAcessorio.sensorRS232) {
                        return reject("Informe o tipo do sensor da RS232");
                    }
                }
                
                return resolve(true);
                
            });
        },
        ativar() {
            let self_ = this;
            this.$vs.dialog({
                type: 'confirm', color: 'warning', title: 'Atenção', text: 'Deseja ativar o acessório '
                 + self_.dadosAtivacaoAcessorio.acessorio.marca + ' ' + self_.dadosAtivacaoAcessorio.acessorio.serial 
                 + ' no veículo ' +  self_.dadosAtivacaoAcessorio.veiculo.placa + ' ? ', acceptText: 'Sim', cancelText: 'Não', accept: this.ativarRequest
            });
        },
        ativarRequest() {
            this.$vs.loading();  
            const params = new URLSearchParams();        
            params.append('outputFormat', 'JSON');
            params.append('method', 'ATIVAR');
            params.append('idVeiculo', this.dadosAtivacaoAcessorio.veiculo.idVeiculo);   
            params.append('idCliente', this.dadosAtivacaoAcessorio.clienteSelecionado.id);   
            params.append('cnpjCPF', this.dadosAtivacaoAcessorio.clienteSelecionado.CNPJCPF);
            params.append('idAcessorioModulo', this.dadosAtivacaoAcessorio.acessorio.id);   
            params.append('mensalidade', this.dadosAtivacaoAcessorio.valorIncrementoNaMensalidade);   
            params.append('localInstalacaoNoVeiculo', this.dadosAtivacaoAcessorio.localInstalacao);   
            if (this.dadosAtivacaoAcessorio.nomeSensorGenerico) {
                params.append('nomeTipoAcessorioModuloGenerico', this.dadosAtivacaoAcessorio.nomeSensorGenerico);   
            }
            if (this.dadosAtivacaoAcessorio.localInstalacao === "ENTRADA1") {
                params.append('avisoAux0', this.dadosAtivacaoAcessorio.sensor);
            } else if (this.dadosAtivacaoAcessorio.localInstalacao === "ENTRADA2") {
                params.append('avisoAux1', this.dadosAtivacaoAcessorio.sensor);
            } else if (this.dadosAtivacaoAcessorio.localInstalacao === "ENTRADA3") {
                params.append('avisoAux2', this.dadosAtivacaoAcessorio.sensor);
            } else if (this.dadosAtivacaoAcessorio.localInstalacao === "SENSOR_RS232") {
                params.append('sensorPortaRS232', this.dadosAtivacaoAcessorio.sensorRS232);
            }
                    
            axios.post("/AcessorioModuloRest", params, {              
                    credentials: 'include', responseEncoding: 'iso88591', withCredentials: true } )
            .then((response) => {
                if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                    this.$router.push({ path: '/' });
                } else {
                    this.$vs.loading.close();
                    if (response.data.statusMensagem === 0) {
                        this.$vs.notify({
                                title: "Mensagem", text: response.data.mensagem, time: 10000,
                                iconPack: 'feather', icon: 'icon-alert-circle', color: 'success' });
                        this.$refs.fwSubsituicao.reset();
                        this.dadosAtivacaoAcessorio.acessorio ={ marca: null, serial: null };
                        this.dadosAtivacaoAcessorio.acessorioDisponivel = false;
                        this.dadosAtivacaoAcessorio.veiculo = null;
                        this.veiculoSelecionado = null;
                        this.fetchListaVeiculos();
                    } else {
                        this.$vs.notify({
                            title: "Erro", text: response.data.mensagem, time: 10000,
                            iconPack: 'feather', icon: 'icon-alert-circle', color: 'warning' });
                    }
                }
            })
            .catch((error) => { 
                this.$vs.loading.close();                
                /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - gravar - " + error);                 
            })  
        },
        pesquisarCliente(options, varTermoPesquisa) {
            varTermoPesquisa = varTermoPesquisa.toUpperCase();
            return options.filter(function (c) {
                    return (c.nome.toUpperCase().indexOf(varTermoPesquisa) != -1  || c.razaoSocial.toUpperCase().indexOf(varTermoPesquisa) != -1 || 
                    c.CNPJCPF.toUpperCase().indexOf(varTermoPesquisa) != -1);
            })
        },        
        fetchListaVeiculos() {     
            if (!this.dadosAtivacaoAcessorio.clienteSelecionado) {
                return;
            }
            
            this.$vs.loading() 
            const params = new URLSearchParams();
            params.append('outputFormat', 'JSON');
            params.append('cpfcnpj', this.dadosAtivacaoAcessorio.clienteSelecionado.CNPJCPF);
            
            axios.post("/RelatorioCliente", params, {              
                    credentials: 'include',
                    responseEncoding: 'iso88591',
                    withCredentials: true } )
            .then((response) => {
                this.$vs.loading.close();
                if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                    this.$router.push({ path: '/' });
                } else {
                    this.listaVeiculos = response.data.listaVeiculos;  
                    this.dadosAtivacaoAcessorio.dadosAdicionaisClienteSelecionado = response.data.dadosCliente;

                    this.exibirMensagemNenhumDadoEncontrado = this.listaVeiculos.length === 0;

                    if (this.listaVeiculos.length > 0 && this.dadosAtivacaoAcessorio.veiculo) {
                        this.veiculoSelecionado = this.dadosAtivacaoAcessorio.veiculo;
                    }
                }
            })
            .catch((error) => { 
                this.$vs.loading.close();
                /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - fetchListaVeiculos - " + error); 
            })    
                                
        },
        fetchListaClientes() {     
            this.$vs.loading() 
            const params = new URLSearchParams();
            params.append('method', 'FindAll');
            params.append('orderDirection', 'ASC');
            params.append('outputFormat', 'JSON');
            params.append('naoExibirInativos', 'true');

            if (this.$store.state.AppActiveUser.perfil === "MONITOR_PANICO" || this.$store.state.AppActiveUser.perfil === "EMPRESA_MONITOR_PANICO") {
                params.append('monitoravelPorCentral', 'true');
            }
            
            axios.post("/ClientesRest", params, {              
                    credentials: 'include',
                    responseEncoding: 'iso88591',
                    withCredentials: true } )
            .then((response) => {
                
                this.$vs.loading.close();
                if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                    this.$router.push({ path: '/' });
                } else {
                    this.listaClientes = response.data;         
                    if (this.$store.getters.dadosBackendAtivacaoAcessorio) {
                       this.dadosAtivacaoAcessorio = JSON.parse(JSON.stringify(this.$store.getters.dadosBackendAtivacaoAcessorio));
                       this.fetchListaVeiculos();
                    }
                }
            })
            .catch((error) => { 
                this.$vs.loading.close();
                /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - fetchLista - " + error); 
            })    
                                
        }, 
        verificarDisponibilidade() {
            this.$vs.loading();  
            const params = new URLSearchParams();        
            params.append('outputFormat', 'JSON');
            params.append('method', 'FIND_BY_SERIAL_MARCA');
            params.append('idCliente', this.dadosAtivacaoAcessorio.clienteSelecionado.id);   
            params.append('cpfCNPJCliente', this.dadosAtivacaoAcessorio.clienteSelecionado.CNPJCPF);
            params.append('serial', this.dadosAtivacaoAcessorio.acessorio.serial);   
            params.append('marca', this.dadosAtivacaoAcessorio.acessorio.marca);   
            params.append('localInstalacaoNoVeiculo', this.dadosAtivacaoAcessorio.localInstalacao);   
                    
            axios.post("/AcessorioModuloRest", params, {              
                    credentials: 'include', responseEncoding: 'iso88591', withCredentials: true } )
            .then((response) => {
                if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                    this.$router.push({ path: '/' });
                } else {
                    this.$vs.loading.close();
                    if (response.data.hasOwnProperty('error')) {
                        this.dadosAtivacaoAcessorio.acessorioDisponivel = false;
                        this.$vs.notify({
                                title: "Mensagem", text: response.data.error, time: 10000,
                                iconPack: 'feather', icon: 'icon-alert-circle', color: 'warning' });
                    } else {
                        this.dadosAtivacaoAcessorio.acessorioDisponivel = true;
                        this.dadosAtivacaoAcessorio.acessorio = response.data;
                        if (this.dadosAtivacaoAcessorio.acessorio.hasOwnProperty('valorIncrementoNaMensalidade')) {
                            this.dadosAtivacaoAcessorio.valorIncrementoNaMensalidade = this.dadosAtivacaoAcessorio.acessorio.valorIncrementoNaMensalidade;
                        } else {
                            this.dadosAtivacaoAcessorio.valorIncrementoNaMensalidade = 15;
                        }
                        this.$vs.notify({
                                title: "Mensagem", text: "Acessório de Módulo disponível", 
                                iconPack: 'feather', icon: 'icon-alert-circle', color: 'success' });
                    }
                }
            })
            .catch((error) => { 
                this.$vs.loading.close();                
                /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - gravar - " + error);                 
            })  
        },
        
    },
    mounted() {               
        this.fetchListaClientes();
    },
    beforeDestroy() {    
        this.$store.dispatch('setDadosBackendAtivacaoAcessorio', this.dadosAtivacaoAcessorio);
    }
}
</script>

<style scoped>
  .tabelaComScroll {      
      overflow: auto;  
      height: calc(100vh - 420px);
      position: relative;      
    } 
</style>

<style lang="scss">  
.con-vs-dialog {    
    .vs-dialog {
      max-width: 1000px;      
    }
}

.con-vs-popup { 
    .vs-popup {
        min-width: 920px;
        max-width: 1000px;      
    }
}
</style>